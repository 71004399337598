<template>
  <el-card style="padding: 20px; background: #fff">
    <avue-form
      :option="option"
      :upload-after="uploadAfter"
      v-model="form"
      ref="crud"
      @submit="rowUpdate"
      @on-load="onLoad"
    >
      <template #content="{disabled,size}">
        <RichText v-model="form.content"></RichText>
      </template>
      <template slot="menuForm">
        <el-button
          type="primary"
          size="small"
          @click="$router.go(-1)"
          icon="el-icon-back"
          >返回</el-button
        >
      </template>
    </avue-form>
  </el-card>
</template>

<script>
import { getList, getDetail, add, update, remove } from "@/api/spcod/prnpolicy";
import { getTreeListAPI } from "@/api/spcod/prncatg.js";
import { mapGetters, mapState } from "vuex";
import { getMetaCommonList , getPicListByIds } from "@/api/busiMode/metaCommon";

export default {
  data() {
    return {
      treeFilterText: "",
      treeData: [],
      defaultProps: {
        children: "subs",
        label: "name",
      },
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      option: {
        submitText: "保 存",
        height: "auto",
        calcHeight: 30,
        tip: false,
        searchShow: true,
        labelWidth: 120,
        searchMenuSpan: 6,
        border: true,
        index: true,
        viewBtn: true,
        dialogClickModal: false,
        column: [
          // {
          //   label: "所属分类",
          //   prop: "catgId",
          //   type: 'select',
          //   dicUrl: '/api/park-spcod/policycatg/prncatgtree',
          //   props: {
          //     label: 'name',
          //     value: 'id'
          //   },
          //   dicFormatter:(data)=>{
          //     console.log(data)
          //     //return data.data.records
          //     // let datalist = data.data.records;
          //     return data.data;
          //   },
          //   rules: [{
          //     required: true,
          //     message: "请输入所属分类",
          //     trigger: "blur"
          //   }]
          // },
          {
            label: "政策标题",
            prop: "title",
            span: 24,
            rules: [
              {
                required: false,
                message: "请输入政策标题",
                trigger: "blur",
              },
            ],
          },
          // {
          //   label: "政策发布时间",
          //   prop: "title",
          //   addDisplay: false,
          //   rules: [{
          //     required: true,
          //     message: "请输入政策发布时间",
          //     trigger: "blur"
          //   }]
          // },
          {
            label: "接收人",
            prop: "visualId",
            searchSpan: 12,
            type: "select",
            dicData: [
              {
                label: "所有人",
                value: 1,
              },
              {
                label: "企业管理员",
                value: 2,
              },
              {
                label: "管理员和员工",
                value: 3,
              },
            ],
            rules: [
              {
                required: true,
                message: "请选择新闻可见范围名称",
                trigger: "blur",
              },
            ],
            change: ({ value, column }) => {
              if (value == undefined || value == "") return;

              let ci = 0;
              this.option.column.forEach((c) => {
                if (c.prop == "deptIds") {
                  if (value == 1) {
                    this.option.column[ci].display = false;
                  } else {
                    this.option.column[ci].display = true;
                  }
                }
                ci += 1;
              });
              this.$forceUpdate();
            },
          },
          {
            label: "企业",
            prop: "deptIds",
            searchSpan: 12,
            type: "select",
            filterable: true,
            multiple: true,
            display: false,
            dicData: [],
            rules: [
              {
                required: true,
                message: "请选择新闻可见的企业",
                trigger: "blur",
              },
            ],
          },
          {
            label: "内容",
            prop: "content",
            // component: "RichText",
            span: 24,
            minRows: 6,
            rules: [
              {
                required: true,
                message: "请输入内容，图文混编",
                trigger: "change",
              },
            ],
          },
          {
            label: "封面",
            prop: "iconUrl",
            component: "form-file",
            params: {
              accept: "image/*",
              multiple: false,
              max: 1,
              useLink: true
            },
            span: 24,
            // type: "upload",
            // listType: "picture-card",
            // span: 24,
            // propsHttp: {
            //   res: "data",
            //   url: "link",
            //   name: "attachId",
            // },
            // tip: "只能上传jpg/png用户头像，且不超过500kb",
            // action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
          {
            label: "政策链接",
            prop: "policyLink",
            span: 24,
            rules: [
              {
                required: false,
                message: "请输入政策链接",
                trigger: "blur",
              },
            ],
          },
          {
            label: "发布时间",
            prop: "releaseTime",
            type: "date",
            span: 12,
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            pickerOptions: {
              disabledDate(time) {
                return time.getTime() < Date.now();
              },
              rules: [
                {
                  required: false,
                  message: "请选择发布时间",
                  trigger: "blur",
                },
              ],
            },
          },
          {
            label: "跳转链接",
            span: 24,
            prop: "externalLinks",
            display: false
          },
          {
            label: "文件",
            span: 24,
            prop: "attaches",
            component: "form-file",
            params: {
              downloadView: true,
            },
            // type: "upload",
            // listType: 'picture-card',
            // propsHttp: {
            //   url: "link",
            //   name: "originalName",
            //   res: "data",
            // },
            // tip: "只能上传文件,且不超过500kb",
            // action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
        ],
      },
      data: [],
    };
  },
  mounted() {
    // this.getTreeList()
    let _this = this;
    getDetail(this.$route.query.id).then((res) => {
      getPicListByIds(['1712377082530500610'])
      this.form = res.data.data;
      console.log(this.form);
      // let files = [];
      // files.push({ label: "noLabel", value: this.form.iconUrl });
      // this.form.files = files;
      // if (this.form.attaches && this.form.attaches.length > 0) {
      //   this.form.attaches.forEach((f) => {
      //     f.name = f.fileName;
      //     f.label = f.fileName;
      //     f.url = f.filePath;
      //   });
      // }
      // getPicListByIds(this.form.files).then((r) => {
      //   if (res && res.data && res.data.data) {
      //     res.data.data.forEach((item) => {
      //       let type = item.originalName.split(".").pop().toLocaleLowerCase();
      //       let fileItem = {
      //         type: type,
      //         url: item.link,
      //         name: item.originalName,
      //         state: "uploaded",
      //         id: item.id,
      //         file: item,
      //       };
      //       this.fileList.push(fileItem);
      //     });
      //   }
      // });
      let includeIconUrl = false;
      ['PARK-NEWS' , 'PARK_ADMIN_MAP' , 'ZCFG' , 'CYFW'].forEach(
        item => {
          if(this.form.catgCode.indexOf(item) > -1){
            includeIconUrl = true;
          }
        }
      );
      const column = this.findObject(this.option.column, "iconUrl");
      if(includeIconUrl){
        column.rules = [
          {
            required: true,
            message: "请上传封面图片",
            trigger: "blur",
          }
        ]
      }else{
        column.rules = [];
      }
      if (this.form.catgCode == "PARK-ADS") {
        for (var i = 0; i < _this.option.column.length; ) {
          let c = _this.option.column[i];
          if(c.prop === 'externalLinks'){
            c.display = true;
          }
          if (c.prop == "policyLink") {
            c.type = "select";
            c.label = "是否允许点击";
            c.dicData = [
              { value: "CLICK_ALLOWED", label: "允许点击" },
              { value: "CLICK_NOT_ALLOWED", label: "只展示" },
            ];
            c.rules[0].required = true;
            c.span = 12;
            i++;
          } else if (c.prop == "attaches") {
            _this.option.column.splice(i, 1);
          } else {
            i++;
          }
        }
      }
    });
    this.getMetaCommonListcheckin();
  },
  computed: {
    ...mapGetters(["permission"]),
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.prnpolicy_add, false),
        viewBtn: this.vaildData(this.permission.prnpolicy_view, false),
        delBtn: this.vaildData(this.permission.prnpolicy_delete, false),
        editBtn: this.vaildData(this.permission.prnpolicy_edit, false),
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  watch: {
    treeFilterText(value) {
      this.$refs.tree.filter(value);
    },
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    getMetaCommonListcheckin() {
      let body = {
        current: 1,
        size: 1000,
        wheres: [{ field: "status", value: 1 }],
      };
      console.log(body);
      getMetaCommonList("wisdom_park.wp_company", body).then((res) => {
        const data = res.data.data;
        if (data.records) {
          let arr = [];
          data.records.forEach((e) => {
            arr.push({
              label: e.dept_name,
              value: e.dept_id,
            });
          });

          this.option.column[2].dicData = arr;
        }
        this.$forceUpdate();
      });
    },
    getTreeDetail(treeData) {
      //对右侧table解析筛选
      let _this = this;
      this.loading = true;
      getList(this.page.currentPage, this.page.pageSize, {
        catgId: treeData.id,
      }).then((res) => {
        const data = res.data.data;
        _this.page.total = data.total;
        _this.data = data.records;
        _this.loading = false;
        this.selectionClear();
      });
    },
    // 获取树形结构数据
    async getTreeList() {
      const res = await getTreeListAPI();
      this.treeData = res.data.data;
    },
    // methods: {
    rowSave(row, done, loading) {
      if (row.files && row.files.length > 0) {
        row.iconUrl = row.files[0].value;
      }
      add(row).then(
        () => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.$router.go(-1);
          done();
        },
        (error) => {
          loading();
          window.console.log(error);
        }
      );
    },
    rowUpdate(row, index, done, loading) {
      row.createUserName = this.userInfo.nick_name;
      if (row.files && row.files.length > 0) row.iconUrl = row.files[0].value;
      if (row.attaches && row.attaches.length > 0) {
        row.attaches.forEach((f) => {
          if (f.label == undefined) {
            f.label = f.fileName;
            f.name = f.fileName;
          }
          if (f.value == undefined) f.value = f.url;
        });
      }
      console.log(row);
      update(row).then(
        () => {
          // this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.$router.go(-1);
          //done();
        },
        (error) => {
          loading();
          console.log(error);
        }
      );
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.$refs.crud.toggleSelection();
        });
    },
    beforeOpen(done, type) {
      if (["edit", "view"].includes(type)) {
        getDetail(this.form.id).then((res) => {
          this.form = res.data.data;
        });
      }
      done();
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        this.loading = false;
        this.selectionClear();
      });
    },
    uploadAfter(res, done, loading, column) {
      // console.log(res,column)
      done();
      this.$message.success("上传成功");
    },
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style></style>
